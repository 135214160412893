import * as React from "react";
import Header from "../components/header";
import "@fontsource/roboto/900-italic.css";
import "@fontsource/roboto/400.css";
import "../styles/terms.scss";
import Footer from "../components/footer";
import Seo from "../components/helmet";

const TermsPage = () => {
  return (
    <React.Fragment>
      <Seo title="Privacy Policy - Flock Finance"></Seo>
      <Header></Header>

      <title>PRIVACY POLICY</title>
      <div className="terms-conditions">
        <h1>PRIVACY POLICY</h1>

        <iframe
          src="/policy.html"
          seamless
          width={"100%"}
          height={"4000px"}
        ></iframe>
      </div>

      <Footer></Footer>
    </React.Fragment>
  );
};

export default TermsPage;
